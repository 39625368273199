import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import MUIDataTable from 'mui-datatables';
import React from "react";
import {styled as muiStyled} from "@mui/material/styles";
import styled from "@emotion/styled";

const StyledTable = muiStyled((props) => <MUIDataTable {...props} />)(
  ({theme}) => ({
    "& .MuiTableCell-body": {
      color: theme.palette.mode === 'light' ? 'black' : 'white',
    },
  }),
);

const StyledContainer = styled(Container)`
  padding: ${({theme}) => theme.spacing(3)};
`;

export default function SimpleTable({emptyMessage, columns, rows}) {

  return (<React.Fragment>
    <StyledContainer maxWidth="lg">
      <Typography component="div">
        <StyledTable
          options={{
            download: false,
            filter: false,
            pagination: true,
            rowsPerPage: 10,
            rowsPerPageOptions: [10, 25, 50],
            search: false,
            selectableRows: "none",
            selectableRowsHeader: false,
            viewColumns: false,
            print: false,
            textLabels: {
              body: {
                noMatch: emptyMessage,
              },
            }
          }}
          columns={columns}
          data={rows}
        />
      </Typography>
    </StyledContainer>
  </React.Fragment>);
}
