import { useAuth } from "../../react-auth-wrapper";
import { useNotifications } from "../../contexts/NotificationsContext";
import React, { useEffect } from "react";
import { usePageMeta } from "../../contexts/PageMetaContext";
import { ADD_SSH_KEY_URL } from '../PathConstants';

export default function CheckIfHasSSHKey({ isAdminView, setShowNoSSHKeyWarning }) {

  const { notify } = useNotifications();
  const { callApi } = useAuth();
  const { goTo } = usePageMeta();
  const cancel = {};

  function runCheck() {
    if (!isAdminView) {
      console.log('[CheckIfHasSSHKey]', 'Fetching data...');
      callApi('/swc/api/profiles/my', { method: 'GET' }, cancel)
      .then((response) => {
        console.log('[SSH keys]', response);
        const { sshKeys } = response;
        if (sshKeys === null || sshKeys.length === 0) {
          setShowNoSSHKeyWarning(true);
          notify('instancesNotificator',
            {
              text: 'No SSH key is configured.',
              type: 'warning',
              actions: [{
                callback: () => {
                  goTo(ADD_SSH_KEY_URL)
                }, title: 'Add SSH key',
              }],
            });
        }
      }).catch((reason) => {
        if (reason.message !== 'Cancelled') {
          notify('instancesNotificator',
            {
              text: "Error while loading profile:",
              error: reason,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      });
    }
  }

  useEffect(runCheck, [])

  return <React.Fragment/>;
}
