import React, {useEffect, useReducer} from 'react';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import GenericProfile from '../components/GenericProfile';
import SshKeys from '../components/SshKeys';
import Ec2UsernameHistory from '../components/Ec2UsernameHistory';
import {initialState, ProfileContext, ProfileReducer} from '../reducers/ProfileReducer';
import UserRoles from '../components/UserRoles';
import {usePageMeta} from '../contexts/PageMetaContext';
import TabPanel from '../components/TabPanel';
import SWCTabs from '../components/SWCTabs';
import {PROFILE_TABS} from '../components/PathConstants';
import ThemeSwitcher from '../components/ThemeSwitcher';
import {useParams} from "react-router-dom";
import styled from "@emotion/styled";
import Paper from "@mui/material/Paper";

const StyledPaper = styled(Paper)`
  width: 100%;
`;
const StyledFlexContainer = styled.div`
  display: flex;
`;
const StyledTypography = styled(Typography)`
  padding-bottom: ${({theme}) => theme.spacing(2)};
  padding-top: ${({theme}) => theme.spacing(2)};
  padding-left: ${({theme}) => theme.spacing(3)};
  width: 90%;
`;
const StyledProfileContainer = styled.div`
  margin-bottom: ${({theme}) => theme.spacing(7)};
  margin-top: 32px;
  padding-left: ${({theme}) => theme.spacing(3)};
`;
const StyledDivider = styled(Divider)`
  margin-top: ${({theme}) => theme.spacing(1)};
`;
export default function Profile() {
  const theme = useTheme();
  const [tabValue, setTabValue] = React.useState(null);
  const [forceReload, dispatchForceReload] = useReducer(ProfileReducer, initialState);
  const {setTitle, goTo} = usePageMeta();
  const {tab} = useParams();
  const tabsInfo = [
    PROFILE_TABS.sshkeys,
    PROFILE_TABS.usernames,
    PROFILE_TABS.roles,
  ];

  setTitle('User profile');

  useEffect(() => {
    const foundTabs = tabsInfo.filter(tabInfo => tabInfo.path.endsWith(tab));
    if (foundTabs.length > 0) {
      setTabValue(foundTabs[0].path);
    } else {
      goTo(tabsInfo[0].path);
    }
  }, [tab]);

  return (
    <ProfileContext.Provider
      value={{state: forceReload, dispatch: dispatchForceReload}}
    >
      <StyledPaper>
        <StyledFlexContainer>
          <StyledTypography variant="h6">
            <Box color="primary.main">Your Profile</Box>
          </StyledTypography>
          <ThemeSwitcher/>
        </StyledFlexContainer>
        <StyledProfileContainer>
          <GenericProfile/>
        </StyledProfileContainer>
        <StyledDivider/>
        <SWCTabs centered variant="fullWidth" activeTabPath={tabValue} tabsInfo={tabsInfo}>
          <TabPanel selectedTabPath={tabValue}
                    tabPath={PROFILE_TABS.sshkeys.path}
                    dir={theme.direction}>
            <SshKeys/>
          </TabPanel>
          <TabPanel selectedTabPath={tabValue}
                    tabPath={PROFILE_TABS.usernames.path}
                    dir={theme.direction}>
            <Ec2UsernameHistory/>
          </TabPanel>
          <TabPanel selectedTabPath={tabValue}
                    tabPath={PROFILE_TABS.roles.path}
                    dir={theme.direction}>
            <UserRoles/>
          </TabPanel>
        </SWCTabs>
      </StyledPaper>
    </ProfileContext.Provider>
  );
}
