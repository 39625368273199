import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {useSearchParams} from "react-router-dom";
import styled from "@emotion/styled";

const StyledTextValidator = styled(TextValidator)`
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;

const StyledButtonContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;
export default function AddSshKey({handleSubmit, hide}) {
  const [params] = useSearchParams();
  const [values, setValues] = React.useState({
    label: '',
    key: params.get("key") ? decodeURIComponent(params.get("key")) : '',
    labelEdited: false
  });

  const keyPattern = /^(ssh-rsa|ssh-dss|ecdsa-sha2-nistp256|ecdsa-sha2-nistp384|ecdsa-sha2-nistp521|ssh-ed25519)[\s]+([A-Za-z0-9\/+]+=*)[\s]*(.*)$/;

  const handleKeyChange = (event) => {
    const newKey = event.target.value;
    if (values.labelEdited === false) {
      const newLabel = getNewLabel(newKey);
      if (newLabel !== '') {
        setValues(prevValues => ({
          ...prevValues,
          ['label']: newLabel,
        }));
      }
    }
    setValues(prevValues => ({
      ...prevValues,
      ['key']: newKey,
    }));
  }

  const handleLabelChange = (event) => {
    const newLabel = event.target.value;
    setValues(prevValues => ({
      ...prevValues,
      ['label']: newLabel,
      ['labelEdited']: true
    }));
  }

  function getNewLabel(newKeyValue) {
    const formattedKey = format(newKeyValue);
    const keyMatch = formattedKey.match(keyPattern);
    if (keyMatch !== null && keyMatch.length === 4) {
      return keyMatch[3]
    }
    return '';
  }

  function format(key) {
    return key.replace(/[\n\t\r]/g, '').trim();
  }

  useEffect(() => {
    if (params.key) {
      handleKeyChange({target: {value: params.key}})
    }
  }, [params.key])

  ValidatorForm.addValidationRule('validSshKey',
    value => {

      if (value !== undefined && value.length > 0) {
        const formattedKey = format(value);
        return formattedKey.match(keyPattern) !== null
      } else {
        return false;
      }
    });
  ValidatorForm.addValidationRule('nonEmptyString',
    value => value !== undefined && value.length > 0 && value.replace(/\s/g, '') !== '');

  return (
    <React.Fragment>
      <CssBaseline/>
      <Container maxWidth="lg">
        <Typography component="div">
          <ValidatorForm autoComplete="off" onSubmit={handleSubmit}>
            <StyledTextValidator
              required
              id="key-value"
              name="keyValue"
              label="SSH Key"
              multiline
              rowsMax="10"
              value={values.key}
              placeholder="Value of the key"
              onChange={handleKeyChange}
              margin="normal"
              validators={['required', 'validSshKey']}
              errorMessages={['Please set ssh key', 'Ssh key is not valid']}
              helperText="Please use your public key. We support following formats: ssh-rsa, ssh-dss, ecdsa-sha2-nistp256, ecdsa-sha2-nistp384, ecdsa-sha2-nistp521, ssh-ed25519."
              fullWidth
            />
            <StyledTextValidator
              required
              id="key-label"
              name="keyLabel"
              label="Label"
              value={values.label}
              onChange={handleLabelChange}
              validators={['required', 'nonEmptyString']}
              errorMessages={['Please provide a non empty label']}
              placeholder="Descriptive name for the key"
              margin="normal"
              fullWidth
            />
            <StyledButtonContainer>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                sx={{
                  marginRight: (theme) => theme.spacing(1),
                }}
              >
                Add
              </Button>
              <Button variant="outlined" type="button" onClick={hide}>Cancel</Button>
            </StyledButtonContainer>
          </ValidatorForm>
        </Typography>
      </Container>
    </React.Fragment>
  );
}
