import React from 'react';

export const ProfileContext = React.createContext(null);

export const initialState = {
  keysAreUpToDate: true,
  usernameIsUpToDate: true,
};

export const ACTION = {
  NOTIFY_NEW_KEY_ADDED: 'NEW_KEY_ADDED',
  NOTIFY_USERNAME_CHANGED: 'USERNAME_CHANGED',
};

export function ProfileReducer(state, action) {
  switch (action.type) {
    case ACTION.NOTIFY_NEW_KEY_ADDED:
      return {
        keysAreUpToDate: false,
      };
    case ACTION.NOTIFY_USERNAME_CHANGED:
      return {
        usernameIsUpToDate: false,
      };
    default:
      return initialState;
  }
}
