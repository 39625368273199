import React, {useState} from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import styled from "@emotion/styled";

const StyledButtonContainer = styled.div`
  padding-top: ${({theme}) => theme.spacing(2)};
  margin-left: ${({theme}) => theme.spacing(1)};
  margin-right: ${({theme}) => theme.spacing(1)};
`;

export default function ProfileEdit({handleSubmit, hide, defaultUsername}) {
  ProfileEdit.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    hide: PropTypes.func.isRequired,
  };
  const [newLinuxUsername, setNewLinuxUsername] = useState(defaultUsername);
  const helperMessage = "Max 32, min 5 characters long, starts with a letter, lowercase, only numbers and letters are allowed.";

  const handleChange = (event) => {
    setNewLinuxUsername(event.target.value);
  };

  return (
    <React.Fragment>
      <CssBaseline/>
      <Container maxWidth="lg">
        <Typography component="div">
          <ValidatorForm onSubmit={handleSubmit}>
            <TextValidator
              label="Linux username"
              onChange={handleChange}
              value={newLinuxUsername}
              name="newUsername"
              validators={['required', 'matchRegexp:^[a-z][a-z0-9]{4,31}$']}
              errorMessages={['Please set username: ' + helperMessage, 'Username is not valid: ' + helperMessage]}
              helperText={helperMessage}
            />
            <StyledButtonContainer>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                sx={{
                  marginRight: (theme) => theme.spacing(1),
                }}
              >
                Apply
              </Button>
              <Button variant="outlined" type="button" onClick={hide}>Cancel</Button>
            </StyledButtonContainer>
          </ValidatorForm>
        </Typography>
      </Container>
    </React.Fragment>
  );
}
