import React, {useEffect, useState} from 'react';
import {useAuth} from '../react-auth-wrapper';
import {useNotifications} from '../contexts/NotificationsContext';
import SimpleTable from "./SimpleTable";

const EMPTY_TABLE_DATA = {
  columns: [
    {label: 'Role', name: 'role'},
    {label: 'Description', name: 'description'},
  ],
  rows: [],
};

export default function UserRoles() {

  const [roles, setRoles] = useState(EMPTY_TABLE_DATA);
  const {callApi} = useAuth();
  const {notify} = useNotifications();
  const cancel = {};

  useEffect(() => {
    console.log('Loading roles...');
    if (cancel.resetCancel) cancel.resetCancel();
    callApi('/swc/api/profiles/my/roles', {method: 'GET'}, cancel)
      .then((response) => {
        const resultRoles = response.values.reduce(function (res, role) {
          return res.concat({"role": role.name, "description": role.description});
        }, []);
        console.log('[UserRoles] roles', response, 'resultRoles', resultRoles);
        setRoles({...roles, rows: resultRoles});
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          setRoles(EMPTY_TABLE_DATA);
          notify('commonNotificator', {
            text: "Error while loading user roles:",
            error: error,
            type: 'error',
            handleClose: () => {
            }
          })
        }
      });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, []);

  return (<SimpleTable emptyMessage="No roles" columns={roles.columns} rows={roles.rows}/>);
}
