import React, {useEffect, useState} from 'react';
import {usePageMeta} from '../contexts/PageMetaContext';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import ListItemText from '@mui/material/ListItemText';
import {ExpandLess, ExpandMore} from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import PropTypes from 'prop-types';
import {IconButton} from '@mui/material';
import styled from "@emotion/styled";

const StyledContainer = styled.div`
  padding-left: ${({theme}) => theme.spacing(3)};
`;
const FlexContainer = styled.div`
  display: flex;
`;
const shouldMenuItemOpen = (currentPath, menuItemInfo) => currentPath === menuItemInfo.clickActionPath || currentPath.includes(`${menuItemInfo.clickActionPath}/`);
export default function Menu({menuItemInfo, mainOpen, currentPath}) {
  const [menuOpen, setMenuOpen] = useState(shouldMenuItemOpen(currentPath, menuItemInfo));
  const {goTo} = usePageMeta();
  const autocollapseMode = menuItemInfo?.visualization === 'AUTOCOLLAPSE';

  function handleMenuOpen() {
    if (autocollapseMode) {
      if (menuOpen) {
        setMenuOpen(false);
      } else if (menuItemInfo.clickActionPath === currentPath) {
        setMenuOpen(true);
      } else {
        goTo(menuItemInfo.clickActionPath);
      }
    } else {
      setMenuOpen(previousState => !previousState);
    }
  }

  function handleGoToOverview() {
    if (menuItemInfo.clickActionPath === currentPath) {
      setMenuOpen(prevState => !prevState);
    } else {
      setMenuOpen(true);
      if (menuItemInfo.clickActionPath) {
        goTo(menuItemInfo.clickActionPath);
      }
    }
  }

  function handleClicked(event) {
    event.preventDefault();
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    handleGoToOverview();
  }

  function menuItem(childrenMenuItem) {
    return (
      <StyledContainer
        key={`wrapper=${childrenMenuItem.clickActionPath}#${childrenMenuItem.key}`}>
        <Menu menuItemInfo={childrenMenuItem} mainOpen={mainOpen} currentPath={currentPath}/>
      </StyledContainer>
    );
  }

  function menuItems() {
    return menuItemInfo.items?.map(menuItem);
  }

  function getExpandIcon() {
    return menuOpen ?
      <IconButton onClick={handleMenuOpen} size="large"><ExpandLess/></IconButton> :
      <IconButton onClick={handleMenuOpen} size="large"><ExpandMore/></IconButton>;
  }

  useEffect(() => {
    if (autocollapseMode) {
      if (menuOpen && !currentPath.includes(menuItemInfo?.clickActionPath)) {
        setMenuOpen(false);
        console.log('[Menu] auto closing', menuItemInfo.key);
      } else if (!menuOpen && currentPath.includes(menuItemInfo?.clickActionPath)) {
        setMenuOpen(true);
        console.log('[Menu] auto opening', menuItemInfo.key);
      }
    }
  }, [currentPath]);

  // BEGIN CLOUD-1486 workaround
  // Rm effect in case other sw is enabled again TODO
  useEffect(() => {
    if (autocollapseMode) {
      if (!menuOpen) {
        setMenuOpen(true);
      }
    }
  }, []);
  // END CLOUD-1486 workaround

  let menu = null;
  if (menuItemInfo) {

    menuItemInfo = !autocollapseMode && menuItemInfo.items && menuItemInfo.items.length === 1 ? menuItemInfo.items[0] : menuItemInfo;
    const showItems = (autocollapseMode && menuItemInfo.items) || (menuItemInfo.visualization === 'DEFAULT' && menuItemInfo.items && menuItemInfo.items.length < 5 && menuItemInfo.items.length > 1);
    const menuItemSelected = currentPath === menuItemInfo.clickActionPath || (!showItems && shouldMenuItemOpen(currentPath, menuItemInfo));

    menu = (
      <React.Fragment>
        <FlexContainer>
          <ListItem component="a"
                    href={menuItemInfo.clickActionPath}
                    onClick={handleClicked}
                    selected={menuItemSelected}
                    sx={{
                      minWidth: (theme) => theme.spacing(29),
                    }}
                    button>
            <ListItemIcon>
              <Tooltip
                title={menuItemInfo.description}
                placement="bottom"
              >
                <Icon>{menuItemInfo.icon}</Icon>
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary={menuItemInfo.label}/>
          </ListItem>
          {mainOpen && showItems ? getExpandIcon() : null}
        </FlexContainer>
        {mainOpen && showItems && <Collapse in={menuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuItems()}
          </List>
        </Collapse>}
      </React.Fragment>
    );
  }

  return menu;
}

Menu.propTypes = {
  mainOpen: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired,
  menuItemInfo: PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icon: PropTypes.string,
    visualization: PropTypes.string,
    clickActionPath: PropTypes.string,
  }).isRequired,
};
