import React, {useContext, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import useModal from '../hooks/useModal';
import ModalDialog from './ModalDialog';
import ProfileEdit from './ProfileEdit';
import ProfileDefaultMirrorEdit from './ProfileDefaultMirrorEdit';
import {ACTION, ProfileContext} from '../reducers/ProfileReducer';
import {useAuth} from '../react-auth-wrapper';
import {useNotifications} from '../contexts/NotificationsContext';
import {usePageMeta} from '../contexts/PageMetaContext';
import {usePermissions} from '../contexts/PermissionsContext';
import UserAvatar from './UserAvatar';
import {useSearchParams} from "react-router-dom";
import styled from "@emotion/styled";

const StyledTooltipIconContainer = styled.span`
  vertical-align: text-top;
  margin-left: 1em;
`;
const StyledUserAvatar = styled(UserAvatar)`
  margin: 0;
  width: 80px;
  height: 80px;
`;
const editButtonCss = {
  textAlign: 'left',
};
const initialLinuxUsername = 'n/a';

export default function GenericProfile() {
  const {isShowing: isShowingUsernameDialog, toggle: toggleUsernameDialog} = useModal();
  const {isShowing: isShowingMirrorDialog, toggle: toggleMirrorDialog} = useModal();
  const {notify} = useNotifications();
  const {permissions, hasPermission} = usePermissions();
  const [ec2Username, setEc2Username] = React.useState(initialLinuxUsername);
  const [mirrors, setMirrors] = React.useState(null);
  const [selectedMirror, setSelectedMirror] = React.useState(null);
  const [name, setName] = React.useState('n/a');
  const [email, setEmail] = React.useState('n/a');
  const {dispatch: dispatchForceReload} = useContext(ProfileContext);
  const {callApi} = useAuth();
  const {goTo} = usePageMeta();
  const [params] = useSearchParams();
  const cancel = {};

  function updateUsername(event) {
    event.preventDefault();
    const newUsername = event.target.newUsername.value;
    callApi('/swc/api/profiles/my/ec2Username',
      {
        method: 'POST',
        body: newUsername,
      }, cancel)
      .then((response) => {
        setEc2Username(response.ec2Username);
        dispatchForceReload({type: ACTION.NOTIFY_USERNAME_CHANGED});
        toggleUsernameDialog();
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          toggleUsernameDialog();
          notify('commonNotificator',
            {
              text: 'Error while changing username:',
              error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      });
  }

  function updateMirror(newMirror) {
    callApi('/swc/api/mirrors/preferred', {
      method: 'POST',
      body: JSON.stringify({key: (newMirror || 'no-mirror')}),
    }, cancel)
      .then((response) => {
        setMirrors(response);
        toggleMirrorDialog();
      })
      .catch((error) => {
        if (error.message !== 'Cancelled') {
          toggleMirrorDialog();
          notify('commonNotificator',
            {
              text: 'Error while changing preferred mirror:',
              error,
              type: 'error',
              handleClose: () => {
              },
            });
        }
      });
  }

  function loadProfile() {
    callApi('/swc/api/profiles/my', {method: 'GET'}, cancel)
      .then((response) => {
        setEc2Username(response.ec2Username);
        setName(response.emailAddress);
        setEmail(response.emailAddress);
        dispatchForceReload({type: ACTION.NOTIFY_USERNAME_CHANGED});
      }).catch((reason) => {
      if (reason.message !== 'Cancelled') {
        notify('commonNotificator',
          {
            text: 'Error while loading profile:',
            error: reason,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    });
  }

  function loadMirrors() {
    if (hasPermission('DOWNLOAD_BUILD')) {
      callApi('/swc/api/mirrors', {method: 'GET'}, cancel)
        .then(setMirrors)
        .catch((reason) => {
          if (reason.message !== 'Cancelled') {
            notify('commonNotificator',
              {
                text: 'Error while loading mirrors:',
                error: reason,
                type: 'error',
                handleClose: () => {
                },
              });
          }
        });
    }
  }

  const calculateSelectedMirror = () => {
    const selected = (mirrors || []).find(mirror => mirror.matches && mirror.matches.find(match => match === 'PREFERRED'));
    setSelectedMirror(selected);
  };

  function handleEditLinuxUsernameClicked() {
    goTo('/profile?q=edit-linux-username');
    toggleUsernameDialog();
  }

  function handleEditMirrorClicked() {
    goTo('/profile?q=edit-preferred-mirror');
    toggleMirrorDialog();
  }

  useEffect(calculateSelectedMirror, [mirrors]);
  useEffect(loadMirrors, [permissions]);
  useEffect(() => {
    if (params.get("q") !== null && params.get("q") === 'edit-linux-username') {
      toggleUsernameDialog();
    }
    if (params.get("q") !== null && params.get("q") === 'edit-preferred-mirror') {
      toggleMirrorDialog();
    }
  }, [permissions]);
  useEffect(loadProfile, []);
  useEffect(() => () => {
    if (cancel && cancel.doCancel) cancel.doCancel();
  }, []);

  const mirrorMarkup = () => {
    let markup = (<i>no preference</i>);
    if (selectedMirror && selectedMirror.label) {
      const {label, description, icon} = selectedMirror;
      let tooltip = description || label;
      if (selectedMirror.matches) {
        tooltip += ` (${selectedMirror.matches.join(', ')})`;
      }
      const mirrorIcon = icon ? (<Icon>{selectedMirror.icon}</Icon>) : null;
      markup = (
        <Tooltip
          title={tooltip}
        >
          <span>
            {label}
            <StyledTooltipIconContainer>
              {mirrorIcon}
            </StyledTooltipIconContainer>
          </span>
        </Tooltip>
      );
    }
    return markup;
  };

  return (
    <Grid container spacing={3} sx={{
      paddingLeft: (theme) => theme.spacing(1),
      paddingRight: (theme) => theme.spacing(1),
    }}>
      <Grid item>
        <StyledUserAvatar/>
      </Grid>
      <Grid item xs={6} sm container spacing={3}>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography gutterBottom variant="subtitle2" color="textSecondary">
              NAME
            </Typography>
            <Typography variant="body2" gutterBottom>
              {name}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography gutterBottom variant="subtitle2" color="textSecondary">
              EMAIL
            </Typography>
            <Typography variant="body2" gutterBottom>
              {email}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Typography gutterBottom variant="subtitle2" color="textSecondary">
              LINUX USERNAME
            </Typography>
            <Typography variant="body2" gutterBottom>
              {ec2Username}
            </Typography>
            <Link
              sx={editButtonCss}
              component="button"
              variant="body2"
              onClick={handleEditLinuxUsernameClicked}
            >
              Edit
            </Link>
          </Grid>
        </Grid>
        {mirrors && (
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
              <Typography gutterBottom variant="subtitle2" color="textSecondary">
                DOWNLOAD MIRROR
              </Typography>
              <Typography variant="body2" gutterBottom>
                {mirrorMarkup()}
              </Typography>
              {mirrors && (
                <Link
                  sx={editButtonCss}
                  component="button"
                  variant="body2"
                  onClick={handleEditMirrorClicked}
                >
                  Edit
                </Link>
              )}
            </Grid>
          </Grid>
        )
        }
      </Grid>
      <ModalDialog
        open={isShowingUsernameDialog}
        toggle={toggleUsernameDialog}
        title="Edit username"
      >
        {ec2Username !== initialLinuxUsername && (
          <ProfileEdit
            handleSubmit={updateUsername}
            hide={toggleUsernameDialog}
            defaultUsername={ec2Username}
          />
        )}
      </ModalDialog>
      {mirrors && (
        <ModalDialog
          open={isShowingMirrorDialog}
          toggle={toggleMirrorDialog}
          title="Edit default download mirror"
        >
          <ProfileDefaultMirrorEdit
            handleSubmit={updateMirror}
            hide={toggleMirrorDialog}
            mirrors={mirrors || []}
          />
        </ModalDialog>
      )}
    </Grid>
  );
}
