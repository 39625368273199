import React, {useContext, useEffect} from 'react';
import './SshKeysTable.css';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import {useAuth} from '../../react-auth-wrapper';
import {ProfileContext} from '../../reducers/ProfileReducer';
import {useNotifications} from '../../contexts/NotificationsContext';
import FormattedDateTime from "../FormattedDateTime";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SimpleTable from "../SimpleTable";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ConfirmationDialog from "../ConfirmationDialog";

export default function SshKeysTable({handleSshKeyLimit}) {
  const INITIAL_DATA = {
    columns: [
      {label: 'Label', name: 'label'},
      {label: 'Key', name: 'key'},
      {
        label: 'Added on', name: 'created', options: {
          customBodyRender: (value) => (value ? <FormattedDateTime dateTimeString={value}/> : null)
        }
      },
      {
        label: 'Action', name: 'action', options: {
          customBodyRender: (key) => (
            <Tooltip
              title="Delete key"
              leaveDelay={600}
              placement="bottom"
            ><IconButton onClick={() => handleOpenDeleteConfirmation(key)}>
              <DeleteOutlinedIcon/>
            </IconButton></Tooltip>
          ),
          sort: false
        },
      },
    ],
    keyRows: [],
  };
  const {state: forceKeyListReload} = useContext(ProfileContext);
  const {notify} = useNotifications();
  const {callApi} = useAuth();
  const [keyTableData, setKeyTableData] = React.useState(INITIAL_DATA);
  const [keyLoaded, setKeyLoaded] = React.useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
  const [keyToDelete, setKeyToDelete] = React.useState(null);
  const cancel = {};

  useEffect(() => {
    callApi('/swc/api/profiles/my', {method: 'GET'}, cancel).then((response) => {
      updateKeyTable(response);
    }).catch((reason) => {
      if (reason.message !== 'Cancelled') {
        notify("commonNotificator",
          {
            text: "Error while loading profile:",
            error: reason,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }, [forceKeyListReload]);

  function updateKeyTable(response) {
    const {sshKeys} = response;
    setKeyTableData({
      ...keyTableData,
      keyRows: sshKeys.map(data => {
          return {
            ...data,
            action: data,
          }
        }
      )
    });
    setKeyLoaded(true);
    handleSshKeyLimit(sshKeys.length >= 5);
  }

  function handleDeleteRow(oldKey) {
    callApi('/swc/api/profiles/my/sshKeys',
      {method: 'DELETE', body: JSON.stringify(oldKey)}, cancel).then((response) => {
      updateKeyTable(response);
    }).catch((reason) => {
      if (reason.message !== 'Cancelled') {
        notify("commonNotificator",
          {
            text: "Error while removing SSH key:",
            error: reason,
            type: "error",
            handleClose: () => {
            }
          });
      }
    }).finally(handleCloseDeleteConfirmation);
  }

  const onRowDelete = () => new Promise((resolve) => {
    setTimeout(() => {
      {
        handleDeleteRow(keyToDelete);
      }
      resolve();
    }, 700);
  });

  function renderNoKeyWarning() {
    if (!keyLoaded) {
      return null;
    }
    return (
      <React.Fragment>
        <Box color="secondary.main">No SSH Key has been configured yet. Please configure your SSH
          Key.</Box>
        <Box color="secondary.main">
          <span>More details can be found </span>
          <Link
            href="https://weshare.advantest.com/vs/display/SWC/SSH+keys"
            target="_blank"
            rel="noopener"
          >
            here
          </Link>
        </Box>
      </React.Fragment>
    );
  }

  function handleOpenDeleteConfirmation(key) {
    setDeleteConfirmOpen(true);
    setKeyToDelete(key);
  }

  function handleCloseDeleteConfirmation() {
    setDeleteConfirmOpen(false);
    setKeyToDelete(null);
  }

  return (
    <React.Fragment>
      <ConfirmationDialog
        open={deleteConfirmOpen}
        handleSubmit={() => onRowDelete()}
        handleCancel={handleCloseDeleteConfirmation}
        title="Delete key"
      >Are you sure to delete this key?</ConfirmationDialog>
      <SimpleTable emptyMessage={renderNoKeyWarning()} columns={keyTableData.columns} rows={keyTableData.keyRows}/>
    </React.Fragment>
  );
}
