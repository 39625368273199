import React, {useEffect, useReducer} from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {amber} from '@mui/material/colors';
import SnackbarContent from '@mui/material/SnackbarContent';
import SshKeysTable from './SshKeysTable/SshKeysTable';
import useModal from '../hooks/useModal';
import ModalDialog from './ModalDialog';
import AddSshKey from './AddSshKey';
import {ACTION, initialState, ProfileContext, ProfileReducer,} from '../reducers/ProfileReducer';
import {useAuth} from '../react-auth-wrapper';
import {useNotifications} from '../contexts/NotificationsContext';
import {usePageMeta} from '../contexts/PageMetaContext';
import {ADD_SSH_KEY_URL} from './PathConstants';
import {useSearchParams} from "react-router-dom";
import styled from "@emotion/styled";

const StyledContainer = styled(Container)`
  padding: ${({theme}) => theme.spacing(3)};
`;
const StyledSnackbarContent = styled(SnackbarContent)`
  background-color: ${amber[700]};
  padding: ${({theme}) => theme.spacing(1)};
  margin-top: ${({theme}) => theme.spacing(1)};
  margin-bottom: ${({theme}) => theme.spacing(1)};
`;
export default function SshKeys() {
  const [forceReload, dispatchForceReload] = useReducer(ProfileReducer, initialState);
  const {notify} = useNotifications();
  const {isShowing, toggle} = useModal();
  const {callApi} = useAuth();
  const {goTo} = usePageMeta();
  const [params] = useSearchParams();
  const cancel = {};

  const [isMaximumSshKeysAdded, setIsMaximumSshKeysAdded] = React.useState(false);

  function addKey(event) {
    event.preventDefault();
    const keyPair = {
      label: event.target.keyLabel.value,
      key: event.target.keyValue.value,
    };
    callApi('/swc/api/profiles/my/sshKeys', {
      method: 'POST',
      body: JSON.stringify(keyPair),
    }, cancel).then(() => {
      toggle();
      dispatchForceReload({type: ACTION.NOTIFY_NEW_KEY_ADDED});
    }).catch((reason) => {
      if (reason.message !== 'Cancelled') {
        toggle();
        notify('commonNotificator',
          {
            text: "Error while adding SSH key:",
            error: reason,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    });
  }

  function handleSshKeyLimit(limitReached) {
    setIsMaximumSshKeysAdded(limitReached);
  }

  function renderSshKeyLimitNotification(limit = 5) {
    const show = isMaximumSshKeysAdded;
    const message = `A user is allowed to add a maximum of ${limit} SSH keys only.`;

    return show ? (
      <div><StyledSnackbarContent message={message}/></div>
    ) : null;
  }

  function renderKeyTable() {
    return (
      <ProfileContext.Provider
        value={{state: forceReload, dispatch: dispatchForceReload}}
      >
        <SshKeysTable handleSshKeyLimit={handleSshKeyLimit}/>
      </ProfileContext.Provider>
    );
  }

  function handleAddSshKeyClicked() {
    goTo(ADD_SSH_KEY_URL);
    toggle();
  }

  useEffect(() => {
    if (params.get("q") !== null && params.get("q") === 'add') {
      toggle();
    }
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, []);

  return (
    <React.Fragment>
      <StyledContainer maxWidth="lg">
        <Typography component="div">
          {renderSshKeyLimitNotification()}
          {renderKeyTable()}
          <Button
            sx={{
              marginTop: (theme) => theme.spacing(1),
              marginRight: (theme) => theme.spacing(1),
            }}
            href=""
            variant="outlined"
            color="primary"
            onClick={handleAddSshKeyClicked}
            disabled={isMaximumSshKeysAdded}
          >
            Add key
          </Button>
        </Typography>
      </StyledContainer>
      <ModalDialog
        open={isShowing}
        toggle={toggle}
        title="New SSH key"
      >
        <AddSshKey handleSubmit={addKey} hide={toggle}/>
      </ModalDialog>
    </React.Fragment>
  );
}
