import React, {useEffect} from "react";
import {useNotifications} from "../../contexts/NotificationsContext";
import {useAuth} from "../../react-auth-wrapper";
import {usePageMeta} from "../../contexts/PageMetaContext";

export default function CheckUsername({setLinuxUsernameValid}) {

  const {notify} = useNotifications();
  const {callApi} = useAuth();
  const {goTo} = usePageMeta();
  const cancel = {};

  function runCheck() {
    callApi('/swc/api/profiles/my', {method: 'GET'}, cancel)
      .then((response) => {
        const checkLinuxUsernameResult = /^[a-z][a-z0-9]{4,31}$/.test(response.ec2Username);
        setLinuxUsernameValid(checkLinuxUsernameResult);
        if (!checkLinuxUsernameResult) {
          notify('instancesNotificator',
            {
              text: 'Your linux-username is not valid. Please change linux-username.',
              type: 'warning',
              actions: [{
                callback: () => {
                  goTo('/profile?q=edit-linux-username')
                }, title: 'Change linux-username',
              }],
            });
        }
      }).catch((reason) => {
      if (reason.message !== 'Cancelled') {
        notify("instancesNotificator",
          {
            text: "Error while loading profile:",
            error: reason,
            type: "error",
            handleClose: () => {
            }
          });
      }
    });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    }
  }

  useEffect(runCheck, []);

  return <React.Fragment/>
}
