import React, { useContext, useEffect } from 'react';
import { ProfileContext } from '../reducers/ProfileReducer';
import { useAuth } from '../react-auth-wrapper';
import { useNotifications } from '../contexts/NotificationsContext';
import SimpleTable from "./SimpleTable";

const EMPTY_TABLE_DATA = {
  columns: [
    { label: 'Username', name: 'ec2Username' },
    { label: 'Changed date', name: 'changeDateString' },
  ],
  usernameRows: [],
};

export default function Ec2UsernameHistory() {
  const { state: forceUsernameHistoryReload } = useContext(ProfileContext);
  const { notify } = useNotifications();
  const [ec2UsernameHistoryItem, setEc2UsernameHistoryItem] = React.useState(EMPTY_TABLE_DATA);
  const { callApi } = useAuth();

  useEffect(() => {
    const cancel = {};
    callApi('/swc/api/profiles/my', { method: 'GET' }, cancel).then((response) => {
      const { ec2UsernameHistory } = response;
      setEc2UsernameHistoryItem({ ...ec2UsernameHistoryItem, usernameRows: ec2UsernameHistory });
    }).catch((reason) => {
      if (reason.message !== 'Cancelled') {
        notify('commonNotificator',
          {
            text: "Error while loading usernames:",
            error: reason,
            type: 'error',
            handleClose: () => {
            },
          });
      }
    });
    return () => {
      if (cancel && cancel.doCancel) cancel.doCancel();
    };
  }, [forceUsernameHistoryReload]);

  return (<SimpleTable emptyMessage="No history" columns={ec2UsernameHistoryItem.columns} rows={ec2UsernameHistoryItem.usernameRows}/>);
}
